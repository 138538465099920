import { useTranslations } from '@vocab/react';
import { Stack, Text, Dialog, TextLink, List } from 'braid-design-system';
import translations_tal from '../../.vocab';
import { useId, useState } from 'react';
export const HowWeDefineImportance = () => {
  const { t: t_tal } = useTranslations(translations_tal);
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();
  return (
    <Stack space="xsmall">
      <Text>
        {t_tal(
          "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
        )}
      </Text>
      <Text>
        <TextLink
          href="#"
          hitArea="large"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          {t_tal('How we define importance')}
        </TextLink>
      </Text>
      <Dialog
        id={id}
        title={t_tal('How we define importance')}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeLabel="Close Dialog"
      >
        <Text>
          Candidates have ranked each driver according to 4 levels of
          importance:
        </Text>
        <List>
          <Text>
            Must-have: Essentials that candidates expect a job to have
          </Text>
          <Text>
            Delighted-if: Extras that differentiate you from other companies
          </Text>
          <Text>
            Not-bothered: Factors that don&apos;t really matter to candidates
          </Text>
          <Text>
            Put-off: Dealbreakers that candidates don&apos;t want in a role.
          </Text>
        </List>
      </Dialog>
    </Stack>
  );
};
