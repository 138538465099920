import type { UseFieldsTypes } from '../components/useFields';

export interface QueryFilterSelectionDisabled {
  primaryDriverOptionsDisabled: boolean;
  secondaryDriverDisabled: boolean;
  secondaryDriverOptionsDisabled: boolean;
}

interface Props {
  primaryDriverSelect: UseFieldsTypes['primaryDriverSelect'];
  primaryDriverOptionsSelect: UseFieldsTypes['primaryDriverOptionsSelect'];
  secondaryDriverSelect: UseFieldsTypes['secondaryDriverSelect'];
}

export const useQueryFilterDisabledFormState = ({
  primaryDriverSelect,
  primaryDriverOptionsSelect,
  secondaryDriverSelect,
}: Props) => {
  const primaryDriverOptionsDisabled =
    primaryDriverSelect.value === '' || primaryDriverSelect.value === 'ALL';

  const secondaryDriverDisabled =
    primaryDriverOptionsDisabled ||
    primaryDriverOptionsSelect.value.length === 0;

  const secondaryDriverOptionsDisabled =
    secondaryDriverSelect.value === '' ||
    secondaryDriverSelect.value === 'null';

  return {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  };
};
