import {
  type CustomHeader,
  HeaderLayout,
  type Locale,
  type Section,
} from '@seek/cmsu-cms-connect';
import { prefixUrlWithLanguage } from '@seek/cmsu-components/src/helpers/prefixUrlWithLanguage';
import type { BreadCrumbItem } from '@seek/cmsu-components';
import type { Country } from '@seek/melways-sites';
import format from 'format-number';

const toTitleCase = (str: string): string =>
  str
    .split('-')
    .map((part) => part[0].toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

export const isNumber = (value: any): boolean => !isNaN(Number(value));

export const getBreadcrumbItems = (
  locale: Locale,
  section: string,
  parentCategoryName: string,
  parentCategorySlug: string,
  categoryName: string,
  categorySlug: string,
  title: string = '',
  parentSection?: string,
) => {
  const items: BreadCrumbItem[] = [
    {
      text: toTitleCase(section),
      url: prefixUrlWithLanguage({
        url: `${parentSection ? `/${parentSection}` : ''}/${section}`,
        locale,
      }),
    },
    {
      text: categoryName,
      url: prefixUrlWithLanguage({
        url: `${
          parentSection ? `/${parentSection}` : ''
        }/${section}/category/${categorySlug}`,
        locale,
      }),
    },
  ];
  if (parentCategoryName.trim().length) {
    items.splice(1, 0, {
      text: parentCategoryName,
      url: prefixUrlWithLanguage({
        url: `/${section}/category/${parentCategorySlug}`,
        locale,
      }),
    });
  }
  if (title.trim().length) {
    items.push({
      text: title,
    });
  }
  items[items.length - 1].url = undefined;
  return items;
};

export const enabledSubscriptionForm = (section: string) =>
  ['hiring-advice', 'market-insights'].includes(section);

export const removeLanguagePath = (pageUrl: string, language: string) => {
  // remove extra language path from non-english language
  if (pageUrl.startsWith(`/${language}/`)) {
    return pageUrl.substring(3);
  }

  return pageUrl;
};

export const currencySymbolByCountry = (country: Country) => {
  switch (country) {
    case 'my':
      return 'RM';
    case 'id':
      return 'RP';
    case 'th':
      return '฿';
    case 'ph':
      return '₱';
    default:
      return '$';
  }
};

export const formatCurrency = (amount: number, country: Country) =>
  format({ prefix: currencySymbolByCountry(country) })(amount);

interface HeaderProps {
  type: 'SeekHeader' | 'CustomHeader';
  layout?: HeaderLayout;
  logo?: CustomHeader['logo'];
  links?: CustomHeader['links'];
  divider?: CustomHeader['divider'];
}

export const getHeaderConfig = (
  headerOptions: Section['headerOptions'],
): HeaderProps => {
  if (headerOptions?.headerType?.__typename === 'CustomHeader') {
    return {
      type: 'CustomHeader',
      logo: headerOptions.headerType.logo,
      links: headerOptions.headerType.links,
      divider: headerOptions.headerType.divider,
    };
  }

  if (headerOptions?.headerType?.__typename === 'SeekHeader') {
    return {
      type: 'SeekHeader',
      layout: headerOptions.headerType.layout || HeaderLayout.Full,
    };
  }

  return { type: 'SeekHeader' };
};
