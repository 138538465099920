import { DrawerOverlay } from '@seek/cmsu-components/src/modules/DrawerOverlay/DrawerOverlay';
import { Text } from 'braid-design-system';
import type { Dispatch, SetStateAction } from 'react';
import type { DriverList } from './QueryFilter';
import { QueryFilterForm } from './QueryFilterForm';
import translations from '../../../.vocab';
import translations_tal from '../.vocab';
import { useTranslations } from '@vocab/react';
import { QueryFilterDrawerAction } from './QueryFilterDrawerAction';
import type { DatalabQueryParams } from '../datalabHelper';

type QueryFilterDrawerProps = {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
  showFilterDrawer?: boolean;
  setShowFilterDrawer: Dispatch<SetStateAction<boolean>>;
};

export const QueryFilterDrawer = ({
  driverList,
  queryFilterParams,
  setShowFilterDrawer,
  showFilterDrawer = false,
}: QueryFilterDrawerProps) => {
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);

  const totalFilters =
    (queryFilterParams.primaryDriver ? 1 : 0) +
    (queryFilterParams.primaryDriverOptions
      ? queryFilterParams.primaryDriverOptions.length
      : 0) +
    (queryFilterParams.secondaryDriver ? 1 : 0) +
    (queryFilterParams.secondaryDriverOptions
      ? queryFilterParams.secondaryDriverOptions.length
      : 0);

  return (
    <>
      <QueryFilterDrawerAction
        filterTitle="Filters"
        setShowFilterDrawer={setShowFilterDrawer}
        totalFilters={totalFilters}
      />
      <DrawerOverlay
        id="loa-query-filter-dialog"
        width="small"
        open={showFilterDrawer}
        setShowFilterDrawer={setShowFilterDrawer}
        title={t('Filters')}
        description={
          <Text>
            {t_tal(
              'Select filters to uncover deeper insights about candidates',
            )}
          </Text>
        }
      >
        <QueryFilterForm
          driverList={driverList}
          queryFilterParams={queryFilterParams}
        />
      </DrawerOverlay>
    </>
  );
};
