import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import { useLoaderData } from 'react-router';
import { PageLayout } from '../../components/PageLayout/PageLayout';
import { useConfig } from '../../shared/hooks';
import { useTargetBlank } from '../../shared/hooks/useTargetBlank';
import type { LayoutProps } from '../../shared/types';
import type { DatalabSurvey } from './datalabHelper';
import { Theme } from '@seek/cmsu-cms-connect';
import { Datalab } from './Datalab';

// TODO!: Replace with actual page title
const pageTitle = 'Talent Acquisition Lab';
const layoutProps: LayoutProps = {
  title: 'Loading...',
};

export const DatalabPage = () => {
  useTargetBlank();
  // Get the params from context.
  const { language } = useConfig();

  const datalabSurvey = useLoaderData() as DatalabSurvey;
  return (
    <PageLayout
      layoutProps={{
        ...layoutProps,
        title: pageTitle,
        section: { theme: Theme.SeekJobs },
      }}
    >
      <CMSUProvider config={{ language }}>
        <Datalab datalabSurvey={datalabSurvey} />
      </CMSUProvider>
    </PageLayout>
  );
};
