import { EmployerHeader, EmployerFooter } from '@seek/adv-header-footer';
import camelCase from 'camelcase';
import { useMemo } from 'react';

import { useAuth, useConfig } from 'src/client/shared/hooks';
import type { EmployerSiteName, LanguageNoZh } from 'src/client/shared/types';
import { HELP_CENTRE_LOCALISED_URL } from '@seek/shared-constants';
import type { SiteName } from '@seek/melways-sites';
import { trackHeaderFooterInteractions } from 'src/client/shared/utils/tealiumTracker/tealiumAdapterHelper';
import type { Section } from '@seek/cmsu-cms-connect';
import { getHeaderConfig } from 'src/client/shared/utils/helper';
import { CustomPageHeader } from '@seek/cmsu-components';

interface Props {
  children: React.ReactNode;
  section?: Partial<Section>;
  categoryName?: string;
}
interface TabIdProps {
  [key: string]: string;
}

type ActiveTabID = NonNullable<
  React.ComponentProps<typeof EmployerHeader>['activeTabId']
>;

type ActiveSubTabId = NonNullable<
  React.ComponentProps<typeof EmployerHeader>['activeSubTabId']
>;

const getProductTabId: TabIdProps = {
  jobads: 'jobAds',
  adcentre: 'candidateManagement',
  talentsearch: 'talentSearch',
  branding: 'branding',
  'company-profiles': 'companyProfiles',
  products: 'whatsNew',
};

export const EmployerLayout = ({ children, section, categoryName }: Props) => {
  const {
    site,
    melwaysLocale,
    isStaging,
    language,
    routeArray,
    country,
    hygraphLocale,
  } = useConfig();
  const { hirerAccountContext } = useAuth();
  const { sectionName, headerOptions } = section || {};

  const { type, layout, logo, links } = getHeaderConfig(headerOptions);

  /*
    TabId is used to highlight the active tab in the header, but requires a camelCase string.
    Currently the 'categoryName' prop is supplied using the page 'slug' - I don't know why the change in terminology
  */

  const activeSubTabId = useMemo(() => {
    /*
      Note: The `products` section categoryNames often are not hyphenated - or even
      change naming completely, so we need to convert them to camelCase manually
      The 'What's New' tab is the default tab for the 'products' section and doesn't
      have a page slug, so is provided as 'undefined'
    */
    if (sectionName === 'products') {
      return categoryName ? getProductTabId[categoryName] : 'whatsNew';
    }
    return categoryName ? camelCase(categoryName) : 'none';
  }, [categoryName, sectionName]);

  const activeTabId = useMemo(
    () => (sectionName ? camelCase(sectionName) : 'none'),
    [sectionName],
  );

  const userIdentityProps = {
    advertiser: hirerAccountContext?.advertiser,
    identityContext: hirerAccountContext?.identityContext,
    user: hirerAccountContext?.user,
  };

  return (
    <>
      {/*
        Note: The header and footer relies on the `window` object to get URLs for the language selector which causes hydration issues since `window` is undefined on the server.
        @ref https://github.com/SEEK-Jobs/metropolis/blob/master/packages/anz-employer-header-footer/src/EmployerHeader/components/LanguageSelector/getWindowPathWithoutLanguage.ts
      */}
      {type === 'SeekHeader' && layout !== 'none' ? (
        <EmployerHeader
          activeSubTabId={activeSubTabId as ActiveSubTabId}
          activeTabId={activeTabId as ActiveTabID}
          basic={layout === 'basic'}
          isStaging={isStaging}
          locale={melwaysLocale}
          site={site as EmployerSiteName}
          onTrackedItemInteraction={trackHeaderFooterInteractions}
          {...(layout !== 'basic' && userIdentityProps)}
        />
      ) : (
        <CustomPageHeader
          logo={logo}
          links={links}
          language={language}
          route={routeArray}
          country={country}
          locale={hygraphLocale}
        />
      )}
      {children}
      <EmployerFooter
        isStaging={false}
        locale={melwaysLocale}
        site={site as EmployerSiteName}
        localisedContactInfo={{
          helpCentreLink:
            HELP_CENTRE_LOCALISED_URL[
              site as Exclude<
                SiteName,
                | 'candidate-seek-root'
                | 'candidate-jobsdb-root'
                | 'candidate-jobstreet-root'
              >
            ][language as LanguageNoZh],
        }}
        {...userIdentityProps}
      />
    </>
  );
};
