import {
  Box,
  Column,
  Columns,
  ContentBlock,
  Hidden,
  PageBlock,
  Stack,
  Text,
  ToastProvider,
} from 'braid-design-system';
import { useState, type Dispatch, type SetStateAction } from 'react';
import type { GetSurveyQuery } from '../../../gql/tal/generated';
import { useConfig } from '../../shared/hooks';
import { useTargetBlank } from '../../shared/hooks/useTargetBlank';
import { DriverSummary } from './components/DriverSummary/DriverSummary';
import type { DriverList } from './components/QueryFilter';
import { QueryFilterDrawer } from './components/QueryFilterDrawer';
import { QueryFilterForm } from './components/QueryFilterForm';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabQueryParams,
  type DatalabSurvey,
} from './datalabHelper';
import { CandidateInformation } from './components/CandidateInformation/CandidateInformation';
import { DriverBreakdown } from './components/DriverBreakdown/DriverBreakdown';
import { NoDataDisplay } from './components/NoDataDisplay/NoDataDisplay';
import { vars } from 'braid-design-system/css';
import { useTranslations } from '@vocab/react';
import translations_tal from './.vocab';

// TODO!: Replace with actual page title
const pageTitle = 'Talent Acquisition Lab';

interface DatalabProps {
  datalabSurvey: DatalabSurvey;
}

export const Datalab = ({
  datalabSurvey: { queryFilters, drivers, surveyResults, queryFilterParams },
}: DatalabProps) => {
  useTargetBlank();
  // Get the params from context.
  const {
    // melwaysLocale, fullURL, sourceName, privacyUrl, country,
    fullURL,
  } = useConfig();

  const driverList: DriverList = datalabHelper.getDriverListFrom(queryFilters);
  const [showFilterDrawer, setShowDrawerFilter] = useState<boolean>(false);

  const showNoData: boolean = datalabHelper.hasNoSurveyData(surveyResults);

  return (
    <ToastProvider>
      <PageBlock width="large">
        <ContentBlock width="large">
          <Box paddingY={'large'}>
            <Columns
              space={{ mobile: 'medium', tablet: 'xlarge' }}
              collapseBelow="desktop"
            >
              <Column width="content">
                <DatalabNavigation
                  driverList={driverList}
                  queryFilterParams={queryFilterParams}
                  setShowDrawerFilter={setShowDrawerFilter}
                  showFilterDrawer={showFilterDrawer}
                />
              </Column>
              <VerticalDivider />
              <Column>
                <Box>
                  {showNoData ? (
                    <NoDataDisplay />
                  ) : (
                    <Stack space="gutter">
                      <DatalabResult
                        fullURL={fullURL}
                        queryFilterParams={queryFilterParams}
                        drivers={drivers}
                        surveyResults={surveyResults}
                      />
                      <CandidateInformation
                        demographic={surveyResults.demographic}
                      />
                    </Stack>
                  )}
                </Box>
              </Column>
            </Columns>
          </Box>
        </ContentBlock>
      </PageBlock>
    </ToastProvider>
  );
};

interface DatalabNavigationProps {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
  setShowDrawerFilter: Dispatch<SetStateAction<boolean>>;
  showFilterDrawer: boolean;
}

const DatalabNavigation = ({
  driverList,
  queryFilterParams,
  setShowDrawerFilter,
  showFilterDrawer,
}: DatalabNavigationProps) => (
  <>
    <Hidden below="desktop">
      <Box display={'flex'} flexShrink={0} style={{ width: '350px' }}>
        <QueryFilterForm
          driverList={driverList}
          queryFilterParams={queryFilterParams}
        />
      </Box>
    </Hidden>
    <Hidden above="tablet">
      <QueryFilterDrawer
        driverList={driverList}
        queryFilterParams={queryFilterParams}
        setShowFilterDrawer={setShowDrawerFilter}
        showFilterDrawer={showFilterDrawer}
      />
    </Hidden>
  </>
);

interface DatalabResultProps {
  fullURL: string;
  queryFilterParams: DatalabQueryParams;
  drivers: DatalabDriverData;
  surveyResults: GetSurveyQuery;
}

const DatalabResult = ({
  fullURL,
  queryFilterParams,
  drivers,
  surveyResults,
}: DatalabResultProps) => {
  const filteredDriverOptions: string =
    datalabHelper.generateDriverSummaryTitleFromFilteredDriverOptionsInQueryParam(
      queryFilterParams,
    );

  return (
    <Box>
      <Stack space="xlarge">
        <DriverSummary
          pageUrl={fullURL}
          pageTitle={pageTitle}
          filteredDriverOptions={filteredDriverOptions}
          respondentCount={surveyResults.respondents}
          candidateCount={surveyResults.demographic.respondants}
        />
        <Box>
          <DriverDescription />
        </Box>

        <DriverBreakdown drivers={drivers} />
      </Stack>
    </Box>
  );
};

const DriverDescription = () => {
  const { t: t_tal } = useTranslations(translations_tal);
  return (
    <Stack space="gutter">
      <Text size="large" weight="strong">
        {t_tal('Drivers that attract these candidates')}{' '}
      </Text>
      <Text>
        {t_tal(
          'These are the drivers candidates consider when looking at roles.',
        )}{' '}
        {t_tal('Drivers are ranked with the most important driver at the top.')}{' '}
        {t_tal('Expand each driver for more detail.')}
      </Text>
    </Stack>
  );
};

const VerticalDivider = () => (
  <Column width="content" hideBelow="desktop">
    <Box
      style={{
        height: '100%',
        width: '2px',
        backgroundColor: vars.backgroundColor.neutralLight,
      }}
    />
  </Column>
);
