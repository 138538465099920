import { json, type LoaderFunction } from 'react-router-dom';
import type { RouteProps } from './routes';
import {
  GetFilterFieldsDocument,
  type GetFilterFieldsQuery,
  GetSurveyDocument,
  type GetSurveyQuery,
} from './gql/tal/generated';
import { internalError, notFound } from './responses';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabSurvey,
} from './client/pages/talent-attraction-lab/datalabHelper';
import type { Locale } from '@seek/melways-sites';
import { datalabMapper } from './client/pages/talent-attraction-lab/datalabMapper';

export interface DatalabLoaderProps {
  clientTAL: ApolloClient<NormalizedCacheObject>;
  locale: Locale;
  request: Request;
}

export type QueryFilter = {
  driverType: string;
  driverValues: string[];
  drilldownType?: string;
  drilldownValues: string[];
};

const getQueryFilters = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  locale: string,
): Promise<GetFilterFieldsQuery> => {
  const { data, error } = await clientTAL.query<GetFilterFieldsQuery>({
    query: GetFilterFieldsDocument,
    variables: { locale },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    throw internalError(error);
  }

  return data;
};

const getSurveyResult = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  queryFilter: QueryFilter,
  locale: string,
): Promise<GetSurveyQuery> => {
  const drilldown =
    queryFilter.drilldownType && queryFilter.drilldownValues.length > 0
      ? {
          drilldown: {
            type: queryFilter.drilldownType?.toUpperCase().replaceAll(' ', '_'),
            value: queryFilter.drilldownValues,
          },
        }
      : undefined;

  const variables = {
    locale,
    driver: {
      type: queryFilter.driverType.toUpperCase().replaceAll(' ', '_'),
      value: queryFilter.driverValues,
    },
    ...drilldown,
  };

  const { data, error } = await clientTAL.query<GetSurveyQuery>({
    query: GetSurveyDocument,
    variables,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    throw internalError(error);
  }

  return data;
};

export const loadDatalabData = async ({
  clientTAL,
  locale,
  request,
}: DatalabLoaderProps): Promise<DatalabSurvey> => {
  const searchParams = new URLSearchParams(
    request.url.substring(request.url.indexOf('?')),
  );
  const queryFilterParams = datalabHelper.getQueryFilterParams(searchParams);

  const surveyFilter: QueryFilter = {
    driverType: queryFilterParams.primaryDriver,
    driverValues: queryFilterParams.primaryDriverOptions || [],
    drilldownType: queryFilterParams.secondaryDriver,
    drilldownValues: queryFilterParams.secondaryDriverOptions || [],
  };

  const queryFilters: GetFilterFieldsQuery = await getQueryFilters(
    clientTAL,
    locale,
  );
  const surveyResults: GetSurveyQuery = await getSurveyResult(
    clientTAL,
    surveyFilter,
    locale,
  );

  const drivers: DatalabDriverData =
    datalabMapper.mapToDatalabDriverData(surveyResults);

  return {
    queryFilters,
    surveyResults,
    drivers,
    queryFilterParams,
  } as DatalabSurvey;
};

export const datalabLoader =
  ({ config, clientTAL }: RouteProps): LoaderFunction =>
  async ({ request, params }) => {
    const { melwaysLocale } = config;
    const { slug } = params;

    // DISABLED FOR PRODUCTION TO PREVENT INDEXING AND DISCOVERY
    if (config.environment === 'production') return notFound(slug);

    const data = await loadDatalabData({
      clientTAL,
      locale: melwaysLocale,
      request,
    });

    return json(data);
  };
