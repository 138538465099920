import no_match from './images/no-match.png';
import { Box, Heading, Stack, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from '../../.vocab';

import { vars } from 'braid-design-system/css';

export const NoDataDisplay = () => {
  const { t } = useTranslations(translations);
  return (
    <Box textAlign="center">
      <Stack space="large">
        <img
          src={no_match}
          alt={t(
            "We'll show this data when we have more responses from people who match your search.",
          )}
          height="128"
          width="128"
          style={{
            paddingBottom: vars.space.medium,
          }}
        />

        <Heading level="3">
          {t(
            "We'll show this data when we have more responses from people who match your search.",
          )}
        </Heading>

        <Stack space="small">
          <Text tone="secondary">
            {t(
              "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
            )}
          </Text>
          <Text tone="secondary">
            {t(
              'In the meantime, you can adjust your filters to try a broader search.',
            )}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
