import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Sy1LDMAy89yt0YYYe3EnfxFz4FWOrqSCxM47TpmX4d8auA06glAOnxNZ6d7XSTFbb+baZw9sEwGHnmCip0Bwkaof2cQKwRyr2jsM8y+78uRIdO5Jy++FVD1tlWd09Tt4nswv14v+olyPq5S/Uz0K+Fta0WjGLNQrHQZv4O6o3dEYO0mgnSPva7e4Azoy0wo5DnieOVsGRqYUkd+KQzdLiOhQTYWlKYzkchL1n7PBwVlIu2mlwZ6xCy6xQ1DZDhJwmlNtAqaipS3HisCsxmHtpG0e7E/M9oXa/RV4LpUgXzJl6qLOcpuVn45ypviM+nTz87MR/mSKL0pHxAzJlW4WQw8QYOaya1N8PmbhUJ7+EeMmnxJ1vpe6gMSWpb6+ifmHNkcM8He1gj0TgvKLs528L0qM5BU9PFSoS0EiLqEFoBfcV6X6Ft6us7qaBOwpl4QDgQf0gNv1GvX/hVhEX0x9Kr4OpPy7SzVXykOh3c9mIxMf6Lz7Gr2/FkueLUSx9uxGwvurjKmATAZ/7p43GaOcDiVTlYuMEAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = 'cm717s6';
export var BannerTextColumnBoxStyle = 'cm717s4';
export var ShopfrontBannerTextColumnBoxStyle = 'cm717s5';
export var VerticalDivider = 'cm717s8';
export var VerticalDividerText = 'cm717sa';
export var VerticalDividerWrapper = 'cm717s7';
export var VerticalLine = 'cm717s9';
export var bannerContainer = 'cm717s0';
export var headerImageStyle = 'cm717s2';
export var headerImageStyleEnlarged = 'cm717s1';
export var tertiaryImageStyle = 'cm717s3';