import { useTranslations } from '@vocab/react';
import {
  Box,
  Disclosure,
  Divider,
  Hidden,
  IconInfo,
  Stack,
  Strong,
  Text,
  TextDropdown,
  Tiles,
} from 'braid-design-system';
import React, { useMemo, useState, type ReactNode } from 'react';
import translations from '../../../../.vocab';
import translations_tal from '../../.vocab';
import {
  datalabHelper,
  type DatalabDriverData,
  type DriverCategory,
  type PrimaryDriverOption,
  type SecondaryDriverOption,
} from '../../datalabHelper';
import { BarChartFilter } from '../BarChart/BarChartFilter';
import { BarChartItem } from '../BarChart/BarChartItem';
import {
  importanceOptions,
  LevelOfImportance,
} from './LevelOfImportance/LevelOfImportance';
import { HowWeDefineImportance } from '../HowWeDefineImportance.tsx/HowWeDefineImportance';

export type TilesColumns = Parameters<typeof Tiles>[0]['columns'];

const tooltipValue =
  "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.";

interface DriverBreakdownProps {
  drivers: DatalabDriverData;
}

const barColorList: string[] = ['#3E8FE0', '#072254'];
const maxTiles = 2;

export const DriverBreakdown = ({ drivers }: DriverBreakdownProps) => {
  const { t } = useTranslations(translations);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);

  const driverOptions = drivers.driverOptions;

  const categoryOptionsAvailable: string[] = [
    ...new Set(
      driverOptions.flatMap((driverOption) =>
        driverOption.secondaryDriverOptions.flatMap((item) => item.name),
      ),
    ),
  ];

  const getBarColor = (name: string) => {
    const index = categoryOptionsAvailable.findIndex(
      (option) => option === name,
    );
    if (index > -1 && index < barColorList.length) return barColorList[index];
    return barColorList[0];
  };

  useMemo(() => {
    if (sortBy !== undefined) {
      datalabHelper.sortAllSecondaryDriverOptionsInPrimaryDriverOptions(
        driverOptions,
        sortBy,
      );
    }
  }, [driverOptions, sortBy]);

  if (!driverOptions || driverOptions.length === 0) return;

  const maxResultPerPrimaryDriverOption: Map<string, number> =
    datalabHelper.getMaxResultPerPrimaryDriverOption(driverOptions);

  const filteredDriverOptions =
    datalabHelper.filterOutPrimaryDriverOptionWithoutCategoryDriverOptions(
      driverOptions,
    );
  const tilesHeader = driverOptions.length >= maxTiles ? maxTiles : 1;
  const tilesColumns = filteredDriverOptions.length >= maxTiles ? maxTiles : 1;

  const categoryRows = driverOptions[0].categories.map(
    (category) => category.name,
  );

  const showDriverTitle = datalabHelper.showDriverTitle(driverOptions);

  return (
    <Stack space="medium">
      {categoryOptionsAvailable.length > 1 && (
        <>
          <SortBy
            sortByLabel={t('Sort by')}
            sortBy={sortBy || categoryOptionsAvailable[0]}
            setSortBy={setSortBy}
            categoryOptions={categoryOptionsAvailable}
          />
          <Divider />
        </>
      )}

      <MobileView
        driverOptions={driverOptions}
        getBarColor={getBarColor}
        maxResultPerPrimaryDriverOption={maxResultPerPrimaryDriverOption}
        showDriverTitle={showDriverTitle}
      />
      <TabletAndAboveView
        driverOptions={driverOptions}
        getBarColor={getBarColor}
        maxResultPerPrimaryDriverOption={maxResultPerPrimaryDriverOption}
        showDriverTitle={showDriverTitle}
        tilesHeader={tilesHeader}
        tilesColumns={tilesColumns}
        categoryRows={categoryRows}
      />
    </Stack>
  );
};

const MobileView = ({
  driverOptions,
  getBarColor,
  maxResultPerPrimaryDriverOption,
  showDriverTitle,
}: {
  driverOptions: PrimaryDriverOption[];
  getBarColor: (name: string) => string;
  maxResultPerPrimaryDriverOption: Map<string, number>;
  showDriverTitle: boolean;
}) => (
  <Hidden above="mobile">
    <Stack space="medium">
      {driverOptions.map((driverOption) => (
        <Stack space="gutter" key={`HEADER_${driverOption.name}`}>
          <SampleDataInfoMessage
            secondaryDriverOptions={driverOption.secondaryDriverOptions}
            key={`HEADER_INFO_${driverOption.name}`}
          />
          {driverOption.categories.map((category) => (
            <React.Fragment key={driverOption.name + category.name}>
              <DriverBreakdownRow
                category={category}
                getBarColor={getBarColor}
                maxResultPerPrimaryDriverOption={
                  maxResultPerPrimaryDriverOption.get(driverOption.name) || 0
                }
                driverOptionName={driverOption.name}
                showDriverTitle={showDriverTitle}
              />
              <CollapsibleLevelOfImportance
                driverOptions={driverOptions}
                categoryRow={category.name}
                tilesColumns={1}
              />

              <Divider />
            </React.Fragment>
          ))}
        </Stack>
      ))}
    </Stack>
  </Hidden>
);

const TabletAndAboveView = ({
  driverOptions,
  getBarColor,
  maxResultPerPrimaryDriverOption,
  showDriverTitle,
  tilesHeader,
  tilesColumns,
  categoryRows,
}: {
  driverOptions: PrimaryDriverOption[];
  getBarColor: (name: string) => string;
  maxResultPerPrimaryDriverOption: Map<string, number>;
  showDriverTitle: boolean;
  tilesHeader: number;
  tilesColumns: TilesColumns;
  categoryRows: string[];
}) => (
  <Hidden below="tablet">
    <Stack space="gutter">
      <Tiles columns={tilesHeader as TilesColumns} space="small">
        {showDriverTitle &&
          driverOptions.map((driverOption) => (
            <DriverBreakdownHeaderTitle
              driverOption={driverOption}
              key={`HEADER_TITLE_${driverOption.name}`}
            />
          ))}

        {showDriverTitle &&
          driverOptions.map((driverOption) => (
            <Divider key={`HEADER_TITLE_DIVIDER_${driverOption.name}`} />
          ))}

        {driverOptions.map((driverOption) => (
          <SampleDataInfoMessage
            secondaryDriverOptions={driverOption.secondaryDriverOptions}
            key={`HEADER_INFO_${driverOption.name}`}
          />
        ))}
      </Tiles>

      {categoryRows.map((categoryRow) => (
        <React.Fragment key={categoryRow}>
          <Tiles columns={tilesColumns} space="small">
            {driverOptions.map((driverOption) => (
              <DriverBreakdownRow
                category={driverOption.categories.find(
                  (category) => category.name === categoryRow,
                )}
                getBarColor={getBarColor}
                maxResultPerPrimaryDriverOption={
                  maxResultPerPrimaryDriverOption.get(driverOption.name) || 0
                }
                driverOptionName={driverOption.name}
                showDriverTitle={false}
                key={driverOption.name + categoryRow}
              />
            ))}
          </Tiles>
          <CollapsibleLevelOfImportance
            driverOptions={driverOptions}
            categoryRow={categoryRow}
            tilesColumns={tilesColumns}
          />

          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  </Hidden>
);

interface SortByProps {
  sortByLabel: string;
  sortBy: string;
  setSortBy: (value: string) => void;
  categoryOptions: string[];
}
const SortBy = ({
  sortByLabel,
  sortBy,
  setSortBy,
  categoryOptions,
}: SortByProps) => (
  <Box display="flex" justifyContent="flexEnd">
    <Text>
      {sortByLabel}{' '}
      <Strong>
        <TextDropdown
          id="SORT_BY"
          label={sortByLabel}
          value={sortBy}
          onChange={setSortBy}
          options={categoryOptions}
        />
      </Strong>
    </Text>
  </Box>
);

interface DriverBreakdownHeaderTitleProps {
  driverOption: PrimaryDriverOption;
}
const DriverBreakdownHeaderTitle = ({
  driverOption,
}: DriverBreakdownHeaderTitleProps) => (
  <Text weight="strong">{driverOption.name}</Text>
);

interface CollapsibleLevelOfImportance {
  driverOptions?: PrimaryDriverOption[];
  categoryRow: string;
  tilesColumns: TilesColumns;
}
const CollapsibleLevelOfImportance = ({
  driverOptions,
  categoryRow,
  tilesColumns,
}: CollapsibleLevelOfImportance) => {
  const { t } = useTranslations(translations);
  const [refineBy, setRefineBy] = useState<Record<string, boolean>>({
    must: true,
    delight: true,
    putOff: true,
    neutral: true,
  });
  if (!driverOptions || driverOptions.length === 0) return;

  return (
    <Disclosure
      id={`${driverOptions[0].name}_SUBCATEGORY`}
      expandLabel={t('Show details')}
      collapseLabel={t('Hide details')}
    >
      <Stack space="large">
        <HowWeDefineImportance />

        <BarChartFilter
          key={`BARCHART_FILTER_${categoryRow}`}
          filterOptions={importanceOptions}
          values={refineBy}
          onChange={(selectedValues) => setRefineBy(selectedValues)}
        />

        <LevelOfImportance
          refineBy={refineBy}
          driverOptions={driverOptions}
          tilesColumns={tilesColumns}
          categoryRow={categoryRow}
        />
      </Stack>
    </Disclosure>
  );
};

interface DriverBreakdownRowProps {
  category: DriverCategory | undefined;
  maxResultPerPrimaryDriverOption: number;
  getBarColor: (name: string) => string;
  driverOptionName: string;
  showDriverTitle: boolean;
}
const DriverBreakdownRow = ({
  category,
  maxResultPerPrimaryDriverOption,
  getBarColor,
  driverOptionName,
  showDriverTitle = false,
}: DriverBreakdownRowProps) => {
  const { t: t_tal } = useTranslations(translations_tal);

  if (!category) return;

  return (
    <Box>
      <Stack space="small">
        <Text weight="strong">{category.name}</Text>
        {showDriverTitle &&
          driverOptionName !== undefined &&
          driverOptionName.length > 0 && (
            <Text weight="strong" size="small">
              {driverOptionName}
            </Text>
          )}

        {category.driverOptions.map((categoryDriverOption) => (
          <BarChartItem
            key={`BAR_${driverOptionName}_${category.name}_${categoryDriverOption.name}`}
            value={categoryDriverOption.percentage}
            maxValue={100}
            label={categoryDriverOption.name}
            color={getBarColor(categoryDriverOption.name)}
            width={`${
              (categoryDriverOption.value / maxResultPerPrimaryDriverOption) *
              100
            }%`}
            showInfoBox
            infoBoxContent={<Text>{t_tal(tooltipValue)}</Text>}
          />
        ))}
      </Stack>
    </Box>
  );
};

interface SampleDataAlertMessageProps {
  secondaryDriverOptions: SecondaryDriverOption[];
}
const SampleDataInfoMessage = ({
  secondaryDriverOptions,
}: SampleDataAlertMessageProps) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const getTranslationFilter = (driverOption: SecondaryDriverOption) => ({
    Strong: (children: ReactNode) => <Strong>{children}</Strong>,
    u: (children: ReactNode) => <u>{children}</u>,
    filter: `${driverOption.name}`,
  });

  return (
    <Stack space="gutter">
      {secondaryDriverOptions.map((item) => (
        <>
          {item.hasLackOfSampleSizeData && (
            <InfoMessage key={`INFO_SAMPLE_SIZE_${item.name}`}>
              {t_tal(
                'Sample size for selected filters is small, so there is greater margin of error.',
                getTranslationFilter(item),
              )}
            </InfoMessage>
          )}

          {item.hasMissingData && (
            <InfoMessage key={`INFO_NO_DATA_${item.name}`}>
              {t_tal(
                "No data available yet for selected filters. We'll show this when we have enough responses.",
                getTranslationFilter(item),
              )}
            </InfoMessage>
          )}
        </>
      ))}
    </Stack>
  );
};

const InfoMessage = ({ children }: { children: ReactNode }) => (
  <Box>
    <Text size="small" icon={<IconInfo />} tone="info">
      {children}
    </Text>
  </Box>
);
