import { createContext, useContext } from 'react';
import type { Request } from 'express';
import {
  getCountryCode,
  getLanguage,
  getMelwaysLocale,
  getProdHostName,
  toHygraphLocale,
} from 'src/server/utils/hostname';
import {
  type Brand,
  type Country,
  getSiteNameFromHostname,
  getZoneFromSite,
  type Language,
  type Locale,
  type SiteName,
  siteConfig,
  createAbsoluteUrl,
} from '@seek/melways-sites';
import type { Environment } from '../types';
import type { Locale as HygraphLocale } from '@seek/cmsu-cms-connect';
import type { Zone } from '@seek/audience-zones';
import { convertToHygraphSiteName } from '../utils/convertToHygraphSiteName';
import { removeLanguagePath } from '../utils/helper';

// This is a public token accessible on the client-side, so it's okay to hard-code.
const RECAPTCHA_KEY = '6Ldd4ZEjAAAAAB_HPJIts-2I4RAcSzHxzPQWRtpT';

const BRAND_INFO: { [key in Country]: Brand } = {
  hk: 'jobsdb',
  id: 'jobstreet',
  my: 'jobstreet',
  ph: 'jobstreet',
  sg: 'jobstreet',
  th: 'jobsdb',
  au: 'seek',
  nz: 'seek',
};

export type ConfigStateContext = {
  environment: Environment;
  fullURL: string;
  hostname: string;
  language: Language;
  melwaysLocale: Locale;
  hygraphLocale: HygraphLocale;
  hygraphSite: SiteName;
  site: SiteName;
  route: string;
  section: string;
  brand: Brand;
  country: Country;
  isStaging: boolean;
  isProduction: boolean;
  recaptchaKey: string;
  sourceName: string;
  privacyUrl: string;
  zone: Zone;
  isDraft: boolean;
  hygraphContentApi?: string;
  talApi?: string;
  pathname: string;
  routeArray: string[];
  utmParameters?: string;
};

type ConfigProviderProps = React.PropsWithChildren<{
  config: ConfigStateContext;
}>;

const ConfigContext = createContext<ConfigStateContext | undefined>(undefined);

const ConfigProvider = ({ children, config }: ConfigProviderProps) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

/**
 * Gets the config context.
 */
const useConfig = () => {
  const context = useContext(ConfigContext);
  /**
   * If this is used outside of a child of ConfigProvider, throw an error.
   */
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context;
};

/**
 * Create a config from an incoming request.
 * The config should be created server-side then passed to the client.
 *
 * NOTE: These values will be available client-side so DO NOT place sensitive information here.
 */
export function createConfig(req: Request): ConfigStateContext {
  const req_hostname = req.hostname;
  const pathname = req.originalUrl;
  const path = req.path;
  // Melways should give us the x-forwarded-host as a string. If it's not, this request likely isn't using Melways.
  const xForwardedHost =
    typeof req.headers['x-forwarded-host'] === 'string'
      ? req.headers['x-forwarded-host']
      : undefined;
  // Use the production value hostname for all values
  const hostname = getProdHostName(xForwardedHost || req_hostname);
  const siteName = getSiteNameFromHostname(hostname);
  const site = siteConfig[siteName];

  // Convert the melways site name to a Hygraph site name for use in GQL queries.
  const hygraphSite = convertToHygraphSiteName(siteName);

  const language = getLanguage(pathname);
  const melwaysLocale = getMelwaysLocale(siteName, language);

  // Don't support root sites because they aren't connected to a country.
  // Country must be defined to be served the correct header/footer and localized content.
  if (
    siteName === 'candidate-jobstreet-root' ||
    siteName === 'candidate-jobsdb-root' ||
    siteName === 'candidate-seek-root'
  ) {
    throw Error(`Site "${siteName}" not supported.`);
  }

  /**
   * We're checking if the req_hostname contains 'staging' here and setting the environment as 'staging'
   * because SKU forcefully overrides the staging in its start and build script.
   * See https://github.com/seek-oss/sku/blob/master/scripts/start-ssr.js
   * and https://github.com/seek-oss/sku/blob/master/scripts/build-ssr.js
   */
  const environment: Environment = req
    .get('host')
    ?.includes('wpt-cms-unification.webprod.dev.outfra.xyz')
    ? 'staging'
    : (process.env.NODE_ENV as Environment);

  const routeArray = req.url.split('?')[0].split('/'); // url without the query string
  const section = routeArray[1].length === 2 ? routeArray[2] : routeArray[1];
  const country = getCountryCode(site);
  const zone = getZoneFromSite(siteName);

  const fullURL = createAbsoluteUrl({
    product: site.classification.product,
    country,
    path: removeLanguagePath(path, language),
    language,
    staging: environment === 'staging',
  });

  const privacyUrl = createAbsoluteUrl({
    product:
      country === 'au' || country === 'nz'
        ? 'candidate'
        : site.classification.product,
    country,
    path: '/privacy',
    language,
    staging: environment === 'staging',
  });

  // If there is a draft represented in the url, show the draft version of content
  // use a token to display the content
  const tokenURL = ['page/preview', '?draft='];
  const useToken = tokenURL.some((url) => req?.originalUrl.includes(url));

  // Get utm query parameters
  const fullUrl = `${req.protocol}://${req.get('host')}${req.originalUrl}`;
  const parsedUrl = new URL(fullUrl);
  const queryParams = new URLSearchParams(parsedUrl.search);
  const utmParameters = queryParams
    .toString()
    .split('&')
    .filter((param) =>
      ['utm_', 'tracking=', 'source='].some((prefix) =>
        param.startsWith(prefix),
      ),
    )
    .join('&');

  return {
    environment,
    route: req.url,
    section,
    hostname,
    site: siteName,
    hygraphSite,
    country,
    brand: BRAND_INFO[country] || 'seek',
    language: getLanguage(pathname),
    melwaysLocale,
    hygraphLocale: toHygraphLocale(melwaysLocale, false),
    isStaging: environment === 'staging',
    isProduction: environment === 'production',
    recaptchaKey: RECAPTCHA_KEY,
    sourceName: req.originalUrl,
    fullURL,
    privacyUrl,
    zone,
    isDraft: useToken,
    pathname: path,
    routeArray,
    utmParameters,
  };
}

export { ConfigProvider, useConfig };
