import { RichText } from '@seek/cmsu-rich-text';
import {
  Alert,
  Bleed,
  Box,
  ContentBlock,
  Divider,
  Heading,
  Hidden,
  Inline,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import he from 'he';

import { richTextRenderers } from '../components/modules';

import {
  Breadcrumbs,
  CategoryLink,
  SocialMediaShare,
  SubscriptionForm,
} from '@seek/cmsu-components';
import { PageLayout } from '../components/PageLayout/PageLayout';
import type { LayoutProps } from '../shared/types';

import { ArticleType, type Section } from '@seek/cmsu-cms-connect';
import { articleActionByType } from '@seek/cmsu-components/src/helpers/articleHelper';
import { prefixUrlWithLanguage } from '@seek/cmsu-components/src/helpers/prefixUrlWithLanguage';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import translationsArticle from '@seek/cmsu-components/src/modules/ArticleItem/.vocab';
import { Video } from '@seek/cmsu-components/src/modules/Video/Video';
import { resizeImageFromHygraph } from '@seek/cmsu-components/src/utils/imageHelper';
import { useTranslations } from '@vocab/react';
import { useLoaderData } from 'react-router';
import type { ArticleLocalization } from 'src/helpers/loadersHelper';
import { transformSeoData } from 'src/helpers/seo';
import { WPT_SALESFORCE_API } from '../../config';
import translations from '../.vocab';
import { PodcastBanner } from '../components/PodcastBanner/PodcastBanner';
import { PodcastPlayer } from '../components/PodcastPlayer/PodcastPlayer';
import { useConfig } from '../shared/hooks';
import { usePageTracking } from '../shared/hooks/usePageTracking';
import { useTargetBlank } from '../shared/hooks/useTargetBlank';
import type { LocalesInfo } from '../shared/utils/getLocales';
import {
  enabledSubscriptionForm,
  getBreadcrumbItems,
} from '../shared/utils/helper';
import { SubscribeFormContactUsWrapper } from './pageComponents/SubscribeFormContactUsWrapper';

const layoutProps: LayoutProps = {
  title: 'Loading...',
};

// The Hygraph site that the article should be fetched from
type Props = {
  site: string;
  section: string;
  isPreview?: boolean;
};

/**
 * TODO:
 * 2. add SEO meta data in <head>
 */
export const Article = ({ section, isPreview = false }: Props) => {
  useTargetBlank();
  const { t } = useTranslations(translations);
  const { t: t_article } = useTranslations(translationsArticle);

  // Get the params from context.
  const { melwaysLocale, fullURL, sourceName, privacyUrl, country, language } =
    useConfig();

  // Get the article content by using the loader.
  const data = useLoaderData() as ArticleLocalization & LocalesInfo;

  const {
    featureImage,
    category: articleCategory,
    content,
    title,
    articleType,
    authors,
    mediaUrl,
    podcast,
    seo,
    timeToRead,
    locale,
    contactUs,
    formData,
  } = data;

  let contentText = he.decode(content.text.trim().replace(/\\n/g, ' '));
  contentText = contentText.trim().replace(/\n/g, '').replace(/  /g, '');
  const imageUrl = featureImage[0]?.url;
  const height = featureImage[0]?.height;
  const width = featureImage[0]?.width;
  let imageUrlFormatted = imageUrl;
  if (height && width) {
    const resizeHieght = (height * 940) / width;
    const fit = resizeHieght > 485 ? 'crop' : 'clip';
    imageUrlFormatted = resizeImageFromHygraph({
      imageUrl,
      height: 485,
      width: 940,
      fit,
    });
  }

  const pageSeo = transformSeoData(seo || {}, {
    title,
    image: imageUrl,
    description:
      contentText.substring(0, contentText.indexOf('.') + 1) || contentText,
  });
  const imageText = featureImage[0]?.alt;

  const categoryName = articleCategory?.name || '';
  const categorySlug = articleCategory?.slug || '';
  const parentCategoryName = articleCategory?.parent?.name || '';
  const parentCategorySlug = articleCategory?.parent?.slug || '';
  const rawContent = content.raw;
  const embedReferences = content.references;
  const description = seo?.description || title;
  const articleSection = articleCategory?.relatedCategorySection as Section;
  const parentSection =
    articleCategory?.relatedCategorySection?.parentSection?.sectionName;
  const relatedSectionName =
    articleCategory?.relatedCategorySection?.sectionName;
  const items = getBreadcrumbItems(
    locale,
    relatedSectionName ? relatedSectionName : section,
    parentCategoryName,
    parentCategorySlug,
    categoryName,
    categorySlug,
    title,
    parentSection,
  );

  const articleAction = articleActionByType(articleType);
  const isPodcastArticleType = articleType?.toLowerCase() === 'podcast';

  usePageTracking(title.toLocaleLowerCase(), categorySlug);

  const isShowVideo = articleType === ArticleType.Video && Boolean(mediaUrl);
  const showSubscriptionForm = enabledSubscriptionForm(section);
  return (
    <PageLayout
      layoutProps={{
        ...layoutProps,
        title,
        section: {
          sectionName: articleSection?.sectionName,
          theme:
            articleSection?.theme === null ? undefined : articleSection?.theme,
          headerOptions:
            articleSection?.headerOptions === null
              ? undefined
              : articleSection?.headerOptions,
        },
        seo: pageSeo,
        locales: data.locales,
      }}
    >
      <CMSUProvider config={{ language }}>
        <Box paddingBottom="xlarge" background="surface">
          <ContentBlock width="medium">
            <Stack space="none">
              <Box
                paddingX={{
                  mobile: 'gutter',
                  desktop: 'none',
                }}
              >
                <Breadcrumbs items={items} />
              </Box>
              {articlePreviewInfo(isPreview)}
              <Box
                background="surface"
                padding="medium"
                overflow="hidden"
                component="article"
              >
                <Stack space="gutter">
                  {isShowVideo && <Video url={mediaUrl || ''} />}
                  {!isShowVideo && Boolean(imageUrl) && (
                    <Bleed horizontal="large" top="large">
                      <Box
                        background="surface"
                        borderRadius={{
                          mobile: 'none',
                          desktop: 'large',
                        }}
                        overflow="hidden"
                      >
                        <img
                          src={imageUrlFormatted}
                          alt={imageText || title}
                          style={{
                            display: 'block',
                            width: '100%',
                          }}
                        />
                      </Box>
                    </Bleed>
                  )}

                  <Inline
                    space={{
                      mobile: 'small',
                      tablet: 'medium',
                    }}
                    alignY={'center'}
                  >
                    <CategoryLink
                      name={categoryName}
                      slug={categorySlug}
                      basePath={
                        parentSection
                          ? `${section}/${relatedSectionName}`
                          : section
                      }
                      locale={locale}
                    />
                    <Inline space={'xxsmall'} alignY={'center'}>
                      {Boolean(timeToRead) && (
                        <Text tone="secondary">{`${timeToRead} ${t_article(
                          articleAction,
                        )}`}</Text>
                      )}
                      {authors && authors.length > 0 && (
                        <Text tone="secondary">
                          {Boolean(timeToRead) && <>&#183;&nbsp;</>}
                          {t('Written by')}{' '}
                          {authors.map((author, index) => (
                            <span key={author.name}>
                              {
                                <TextLink
                                  href={authorLink(author.slug, locale)}
                                  key={author.name}
                                >
                                  {author.name}
                                </TextLink>
                              }
                              {index < authors.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </Text>
                      )}
                    </Inline>
                  </Inline>

                  <Heading level="2" component="h1">
                    {title}
                  </Heading>
                  <Box paddingBottom="medium">
                    <SocialMediaShare
                      pageUrl={fullURL}
                      title={title}
                      summary={description}
                    />
                  </Box>
                  {isPodcastArticleType && (
                    <PodcastPlayer mediaUrl={mediaUrl} />
                  )}
                  <RichText
                    content={rawContent}
                    renderers={richTextRenderers}
                    references={embedReferences}
                  />
                  {isPodcastArticleType && <PodcastBanner podcast={podcast} />}

                  <Hidden below="tablet">
                    <Divider weight="regular" />
                  </Hidden>

                  <SocialMediaShare
                    pageUrl={fullURL}
                    title={title}
                    summary={description}
                  />
                  <SubscribeFormContactUsWrapper
                    contactUs={contactUs}
                    formData={formData}
                  />
                </Stack>
              </Box>

              {showSubscriptionForm && (
                <Box
                  background="surface"
                  overflow="hidden"
                  marginTop={{
                    mobile: 'gutter',
                    tablet: 'xlarge',
                    desktop: 'xxlarge',
                  }}
                  // 1 set smaller than PageComponent because Article provides padding bottom,
                  // but might need to change if theres an element underneath showSubscriptionForm
                  paddingBottom={{
                    mobile: 'medium',
                    tablet: 'large',
                    desktop: 'xlarge',
                  }}
                >
                  <SubscriptionForm
                    type={section}
                    locale={melwaysLocale}
                    sourceName={sourceName}
                    privacyUrl={privacyUrl}
                    wptSalesForceApi={WPT_SALESFORCE_API}
                    country={country}
                  />
                </Box>
              )}

              {articlePreviewInfo(isPreview)}
            </Stack>
          </ContentBlock>
        </Box>
      </CMSUProvider>
    </PageLayout>
  );
};

export const authorLink = (slug: string, locale: string) =>
  prefixUrlWithLanguage({
    url: `/author/${slug}`,
    locale,
  });

const articlePreviewInfo = (isPreview: boolean) =>
  isPreview && (
    <Box paddingBottom={'medium'}>
      <Alert tone="info">
        <Text>This is an Article Preview</Text>
      </Alert>
    </Box>
  );
