import {
  Box,
  ButtonIcon,
  Heading,
  IconDownload,
  Spread,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
  TooltipRenderer,
} from 'braid-design-system';
import { ShareDropdown } from '../ShareDropdown/ShareDropdown';
import { datalabHelper } from '../../datalabHelper';
import { useTranslations } from '@vocab/react';
import translations_tal from '../../.vocab';
import { vars } from 'braid-design-system/css';
import { useConfig } from 'src/client/shared/hooks';
// TODO!: Add a download button url
// TODO!: Add about navigation link

interface QuerySummaryProps {
  filteredDriverOptions: string;
  respondentCount: number;
  candidateCount: number;
  pageUrl: string;
  pageTitle: string;
}

const DownloadButton = () => {
  const { t } = useTranslations(translations_tal);

  return (
    <ButtonIcon
      size="standard"
      icon={<IconDownload />}
      id="download-pdf-button"
      label={t('Download PDF')}
      variant="soft"
    />
  );
};

const ShareButton = ({
  pageUrl,
  pageTitle,
}: {
  pageUrl: string;
  pageTitle: string;
}) => <ShareDropdown pageUrl={pageUrl} title={pageTitle} />;

const RespondentInfo = ({
  respondentCount,
  candidateCount,
}: {
  respondentCount: number;
  candidateCount: number;
}) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { country } = useConfig();

  const dateRange =
    country === 'au' || country === 'nz'
      ? 'January 2023 - February 2024.'
      : 'April 2024 to May 2024';

  return (
    <Text>
      {t_tal('Results are based on')}{' '}
      <TooltipRenderer
        id="respondent-tooltip"
        tooltip={
          <Text>
            This sample size is statistically significant for the filters you
            have chosen
          </Text>
        }
      >
        {({ triggerProps }) => (
          <Box {...triggerProps} display="inline">
            <TextLinkButton>{candidateCount.toLocaleString()}</TextLinkButton>
          </Box>
        )}
      </TooltipRenderer>{' '}
      {t_tal('respondents from a total of')}{' '}
      <TextLink href={''}>
        {respondentCount.toLocaleString()} {t_tal('candidates surveyed')}
      </TextLink>{' '}
      {dateRange}
    </Text>
  );
};

const DriverSummaryTitle: React.FC<{ filteredDriverOptions: string }> = ({
  filteredDriverOptions,
}) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { title } = datalabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  return <Text>{t_tal(title)}</Text>;
};

const DriverSummaryAction: React.FC<{ pageUrl: string; pageTitle: string }> = ({
  pageUrl,
  pageTitle,
}) => (
  <Box
    display={'flex'}
    flexDirection={'row'}
    style={{
      gap: vars.space.xsmall,
    }}
  >
    <DownloadButton />
    <ShareButton pageUrl={pageUrl} pageTitle={pageTitle} />
  </Box>
);

type DriverSummaryHeaderProps = {
  children: Parameters<typeof Spread>[0]['children'];
};

const DriverSummaryHeader: React.FC<DriverSummaryHeaderProps> = ({
  children,
}) => (
  <Spread space={'large'} alignY={'center'}>
    {children}
  </Spread>
);

const DriverSummaryResult: React.FC<{
  filteredDriverOptions: string;
  respondentCount: number;
  candidateCount: number;
}> = ({ filteredDriverOptions, respondentCount, candidateCount }) => {
  const { hasDriverOptions } = datalabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  const { t: t_tal } = useTranslations(translations_tal);

  return (
    <Stack space={'medium'}>
      <Heading level={'3'}>
        ‘{hasDriverOptions ? filteredDriverOptions : t_tal('All respondents')}’
      </Heading>

      <RespondentInfo
        respondentCount={hasDriverOptions ? respondentCount : candidateCount}
        candidateCount={candidateCount}
      />
    </Stack>
  );
};

type DriverSummaryContentProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContent: React.FC<DriverSummaryContentProps> = ({
  children,
}) => <Stack space={{ mobile: 'gutter', desktop: 'medium' }}>{children}</Stack>;

type DriverSummaryContainerProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContainer: React.FC<DriverSummaryContainerProps> = ({
  children,
}) => <Stack space={'medium'}>{children}</Stack>;

const DriverSummary: React.FC<QuerySummaryProps> = ({
  filteredDriverOptions,
  respondentCount,
  candidateCount,
  pageUrl,
  pageTitle,
}) => (
  <DriverSummaryContainer>
    <DriverSummaryHeader>
      <DriverSummaryTitle filteredDriverOptions={filteredDriverOptions} />
      <DriverSummaryAction pageUrl={pageUrl} pageTitle={pageTitle} />
    </DriverSummaryHeader>
    <DriverSummaryContent>
      <DriverSummaryResult
        filteredDriverOptions={filteredDriverOptions}
        respondentCount={respondentCount}
        candidateCount={candidateCount}
      />
    </DriverSummaryContent>
  </DriverSummaryContainer>
);

export { DriverSummary };
