import { createValidator, required } from '@seek/validators-js';
import { useField } from './QueryFilterForm';
import translations from '../.vocab';
import genericTranslations from '../../../../client/.vocab';
import { useTranslations } from '@vocab/react';
export type UseFieldsTypes = ReturnType<typeof useFields>;

interface PrimaryDriverOptionsProps {
  value: string;
  validatorProps: {
    primaryDriverValue: string;
  };
}

export const { primaryDriverOptionsValidator } = createValidator({
  test: ({ value, validatorProps }: PrimaryDriverOptionsProps) => {
    const { primaryDriverValue } = validatorProps;

    if (primaryDriverValue === 'ALL') {
      return true;
    }

    // could be modified to check array for when we want more than one option
    if (value && value.length > 0) {
      return true;
    }

    return false;
  },
  formatErrorMessages: (messages) => () =>
    messages['Select at least one option'].format(),
  translations,
  validatorName: 'primaryDriverOptionsValidator',
});

interface SecondaryDriverOptionsProps {
  value: string;
  validatorProps: {
    primaryDriverValue: string;
    secondaryDriverValue: string;
  };
}

// this can also be changed to validate in case our options
// become an array of strings
export const { secondaryOptionsValidator } = createValidator({
  test: ({ value, validatorProps }: SecondaryDriverOptionsProps) => {
    const { primaryDriverValue, secondaryDriverValue } = validatorProps;

    if (primaryDriverValue === 'ALL') {
      return true;
    }

    if (primaryDriverValue !== '' && secondaryDriverValue === '') {
      return true;
    }

    // Condition to validate secondary driver and options when the field is not empty
    if (secondaryDriverValue !== '' && value && value.length > 0) {
      return true;
    }
    // Condition to check when the user selects the default option: "Select a filter", which is a null value and having no options
    if (secondaryDriverValue === 'null' && value.length === 0) {
      return true;
    }

    return false;
  },
  formatErrorMessages:
    (messages) =>
    ({}) =>
      messages['Select at least one option'].format(),
  translations,
  validatorName: 'secondaryOptionsValidator',
});

export const useFields = () => {
  const { t } = useTranslations(genericTranslations);

  const primaryDriverSelect = useField({
    id: 'primaryDriverSelect',
    validators: [required],
    validatorProps: {
      requiredMessage: t('Select an option'),
    },
  });
  const primaryDriverOptionsSelect = useField({
    id: 'primaryDriverOptionsSelect',
    validators: [primaryDriverOptionsValidator as any],
    validatorProps: {
      primaryDriverValue: primaryDriverSelect.value,
    },
  });
  const secondaryDriverSelect = useField({
    id: 'secondaryDriverSelect',
    validators: [],
  });
  const secondaryDriverOptionsSelect = useField({
    id: 'secondaryDriverOptionsSelect',
    validators: [secondaryOptionsValidator as any],
    validatorProps: {
      primaryDriverValue: primaryDriverSelect.value,
      secondaryDriverValue: secondaryDriverSelect.value,
    },
  });

  return {
    primaryDriverSelect,
    primaryDriverOptionsSelect,
    secondaryDriverSelect,
    secondaryDriverOptionsSelect,
  };
};
