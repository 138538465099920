import type { Icon } from '@seek/cmsu-cms-connect';
import { renderIcon } from '@seek/cmsu-components';
import { Card, IconPeople, Spread, Stack, Text } from 'braid-design-system';
import React from 'react';

export interface CandidateData {
  name: string;
  value: string;
}

export interface CandidateCardProps {
  titleIcon: Icon;
  title: string;
  data: CandidateData[];
}

export const CandidateCard = ({
  titleIcon,
  title,
  data,
}: CandidateCardProps) => (
  <Card rounded height="full">
    <Stack space="gutter">
      <Text icon={renderIcon(titleIcon) || <IconPeople />} weight="medium">
        {title}
      </Text>

      <CandidateCardData data={data} />
    </Stack>
  </Card>
);

interface CandidateCardDataProps {
  data: CandidateData[];
}

const CandidateCardData = ({ data }: CandidateCardDataProps) => (
  <Stack space="small">
    {data.map((item, index) => (
      <Spread space="small" key={index}>
        <Text>{item.name}</Text>
        <Text>{item.value}</Text>
      </Spread>
    ))}
  </Stack>
);
