import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Dropdown,
  Hidden,
  Inline,
  Stack,
} from 'braid-design-system';
import translations from '../../../.vocab';
import translations_tal from '../.vocab';
import { QueryFilterHeading } from './QueryFilterHeading';
import { useFields, type UseFieldsTypes } from './useFields';
import { datalabHelper } from '../datalabHelper';
import { useMemo, useState } from 'react';
import type { FormValues } from './QueryFilterForm';
import { MultipleSelectDropdown } from '@seek/cmsu-components/src/modules/MultipleSelectDropdown/MultipleSelectDropdown';
import type { RenderParams } from '@seek/forms-ui';
import { useGenerateDriverList } from '../hooks/useGenerateDriverList';
import { useQueryFilterDisabledFormState } from '../hooks/useQueryFilterDisabledFormState';
import { useDriverFilterTranslation } from '../hooks/useDriverFilterTranslation';

interface FilterProp {
  filterLabel?: string;
  filterPlaceholder?: string;
}

interface DriverFilterProp extends FilterProp {
  optionFilter: FilterProp;
}

export interface DriverOption {
  name: string;
}
export interface Driver {
  name: string;
  options: DriverOption[];
}
export interface DriverList {
  drivers: Driver[];
}

interface Props {
  driverList: DriverList;
  handleSubmit: RenderParams<FormValues>['handleSubmit'];
  resetForm: () => void;
  clearField: (fieldName: keyof FormValues) => void;
}

export const QueryFilter = ({
  driverList,
  handleSubmit,
  clearField,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);

  const formFields = useFields();
  const {
    primaryDriverSelect,
    primaryDriverOptionsSelect,
    secondaryDriverSelect,
    secondaryDriverOptionsSelect,
  } = formFields;

  const {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  } = useQueryFilterDisabledFormState({
    primaryDriverOptionsSelect,
    primaryDriverSelect,
    secondaryDriverSelect,
  });

  const {
    primaryDriverList,
    secondaryDriverList,
    primaryOptionsList,
    secondaryOptionsList,
  } = useGenerateDriverList(driverList);

  const { primaryDriverFilter, secondaryDriverFilter } =
    useDriverFilterTranslation();

  // Update driver and option value
  useMemo(() => {
    datalabHelper.updateSecondaryDriverList({
      primaryDrivers: primaryDriverList,
      secondaryDrivers: secondaryDriverList,
      selectedPrimaryDriver: primaryDriverSelect.value,
    });

    // Clear secondary driver and options if primary is same as secondary driver
    if (
      secondaryDriverSelect.value &&
      !secondaryDriverList.drivers.find(
        (driver) => driver.name === secondaryDriverSelect.value,
      )
    ) {
      // secondaryDriverSelect.value = '';
      // secondaryDriverOptionsSelect.value = [];
      clearField('secondaryDriverSelect');
      clearField('secondaryDriverOptionsSelect');
    }
  }, [
    primaryDriverList,
    secondaryDriverList,
    primaryDriverSelect.value,
    secondaryDriverSelect.value,
    clearField,
  ]);

  if (primaryDriverOptionsDisabled) clearField('primaryDriverOptionsSelect');
  if (secondaryDriverDisabled) clearField('secondaryDriverSelect');
  if (secondaryDriverOptionsDisabled)
    clearField('secondaryDriverOptionsSelect');

  const onSubmit = handleSubmit((formValues: FormValues) => {
    setIsLoading(true);
    window.location.href = datalabHelper.generateUrlQueryParamFrom(formValues);
  });

  const handleClearForm = () => {
    clearField('primaryDriverSelect');
    clearField('primaryDriverOptionsSelect');
    clearField('secondaryDriverSelect');
    clearField('secondaryDriverOptionsSelect');
  };

  const buttonLoading = isLoading ? { loading: true } : {};

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Box>
        <Stack space="large">
          <Hidden below="desktop">
            <QueryFilterHeading
              title={t('Filters')}
              description={t_tal(
                `Select filters to uncover deeper insights about candidates`,
              )}
            />
          </Hidden>

          {/* PRIMARY */}
          <DriverFilter
            id="PRIMARY_FILTER"
            driver={primaryDriverSelect}
            driverList={primaryDriverList}
            driverOptionsList={primaryOptionsList}
            driverOptionsSelect={primaryDriverOptionsSelect}
            filterProp={primaryDriverFilter}
            driverDisabled={false}
            driverOptionDisabled={primaryDriverOptionsDisabled}
          />

          {/* SECONDARY */}
          <DriverFilter
            id="SECONDARY_FILTER"
            driver={secondaryDriverSelect}
            driverList={secondaryDriverList}
            driverOptionsList={secondaryOptionsList}
            driverOptionsSelect={secondaryDriverOptionsSelect}
            filterProp={secondaryDriverFilter}
            driverDisabled={secondaryDriverDisabled}
            driverOptionDisabled={secondaryDriverOptionsDisabled}
          />

          <Inline space="small" collapseBelow="tablet">
            <Box style={{ minWidth: '155px' }}>
              <Button
                {...buttonLoading}
                variant="solid"
                type="submit"
                size="standard"
                tone="formAccent"
              >
                {t('Apply filters')}
              </Button>
            </Box>
            <Button
              variant="transparent"
              type="button"
              onClick={handleClearForm}
              tone="formAccent"
            >
              {t('Clear all')}
            </Button>
          </Inline>
        </Stack>
      </Box>
    </form>
  );
};

interface DriverFilterProps {
  id: string;
  driver:
    | UseFieldsTypes['primaryDriverSelect']
    | UseFieldsTypes['secondaryDriverSelect'];
  driverList: DriverList;
  driverOptionsList?: { value: string; label: string }[];
  driverOptionsSelect:
    | UseFieldsTypes['primaryDriverOptionsSelect']
    | UseFieldsTypes['secondaryDriverOptionsSelect'];
  filterProp: DriverFilterProp;
  driverDisabled: boolean;
  driverOptionDisabled?: boolean;
}

const DriverFilter = ({
  id,
  filterProp,
  driverList,
  driverDisabled,
  driverOptionDisabled,
  driverOptionsList,
  driverOptionsSelect,
  driver,
}: DriverFilterProps) => {
  const multiSelectOptionValue = driverOptionsSelect.value.map((item) => ({
    value: item,
    label: item,
  }));

  // Only show placeholder when first item in list is not 'null'
  // 'null' value will make the first drop down as selectable placeholder
  const placeholder =
    driverList.drivers[0].name !== 'null' ? filterProp.filterPlaceholder : '';

  const getFilterLabelFrom = (
    name: string,
    label: string = 'Select a filter',
  ) => (name !== 'null' ? name : label);

  return (
    <Stack space="large">
      <Dropdown
        id={id}
        label={filterProp.filterLabel}
        onChange={(newValue) => {
          driver.onChange(newValue.currentTarget.value);
          driverOptionsSelect.onChange([]);
        }}
        value={driverDisabled ? '' : driver.value}
        disabled={driverDisabled}
        tone={!driver.valid ? 'critical' : 'neutral'}
        placeholder={placeholder}
        message={!driver.valid && driver.errorMessage}
      >
        {driverList.drivers.map((d) => {
          const label = getFilterLabelFrom(
            d.name,
            filterProp.filterPlaceholder,
          );
          return (
            <option key={d.name} value={d.name} label={label}>
              {label}
            </option>
          );
        })}
      </Dropdown>

      <MultipleSelectDropdown
        options={driverOptionsList}
        onChange={(newValue) =>
          driverOptionsSelect.onChange(newValue.map((item) => item.value))
        }
        canSelect={multiSelectOptionValue.length < 2}
        value={multiSelectOptionValue}
        id={driver.value}
        title={filterProp.optionFilter.filterLabel}
        placeholder={filterProp.optionFilter.filterPlaceholder}
        isDisabled={driverOptionDisabled}
        message={driverOptionsSelect?.errorMessage}
        isValid={driverOptionsSelect.valid}
      />
    </Stack>
  );
};
