import { Stack, Text, Tiles } from 'braid-design-system';
import type {
  DriverSubCategory,
  PrimaryDriverOption,
} from '../../../datalabHelper';
import { BarChartGroup } from '../../BarChart/BarChartGroup';
import type { TilesColumns } from '../DriverBreakdown';

const barColorList = ['#5B2084', '#A04BCB', '#1FA575', '#EF672A'] as const;
export const refineByOptions = [
  'must',
  'delight',
  'neutral',
  'putOff',
] as const;
export type RefineByLabel = (typeof refineByOptions)[number];
type LabelMap = {
  readonly [key in RefineByLabel]: string;
};

const refineByLabelMap: LabelMap = {
  must: 'Must have',
  delight: 'Delighted if',
  putOff: 'Put off',
  neutral: 'Not bothered',
} as const;

const getBarColor = (index: number) => {
  if (index > -1 && index < barColorList.length) return barColorList[index];
  return barColorList[0];
};

export const getRefineByLabel = (label: string): string =>
  refineByLabelMap[label as RefineByLabel] || label;

export const importanceOptions = refineByOptions.map((option) => ({
  id: option,
  label: getRefineByLabel(option),
  color: getBarColor(refineByOptions.indexOf(option)),
}));

interface LevelOfImportanceProps {
  driverOptions: PrimaryDriverOption[];
  categoryRow: string;
  refineBy: Record<string, boolean>;
  tilesColumns: TilesColumns;
}

export const LevelOfImportance: React.FC<LevelOfImportanceProps> = ({
  driverOptions,
  categoryRow,
  refineBy,
  tilesColumns,
}) => {
  // ToDo: move to helper and add test coverage
  const mapToSubCategories = (subCategories: DriverSubCategory[]) =>
    subCategories.map((subCategory) => ({
      subCategoryName: subCategory.name,
      driverOptions: subCategory.driverOptions.map(
        (subCategoryDriverOption) => ({
          driverOptionName: subCategoryDriverOption.name,
          items: subCategoryDriverOption.results
            .sort(
              (a, b) =>
                refineByOptions.indexOf(a.name as RefineByLabel) -
                refineByOptions.indexOf(b.name as RefineByLabel),
            )
            .map((item, index) => ({
              label: getRefineByLabel(item.name),
              value: item.percentage,
              color: getBarColor(index),
              hidden: !refineBy[item.name as RefineByLabel],
            })),
        }),
      ),
    }));

  // ToDo: move to helper and add test coverage
  const levelOfImportances = driverOptions.map((driverOption) => ({
    driverOptionName: driverOption.name,
    categoryName: categoryRow,
    subCategories: driverOption.categories
      .filter((category) => category.name === categoryRow)
      .flatMap((category) => mapToSubCategories(category.subCategories)),
  }));

  const subCategoryRows = [
    ...new Set(
      levelOfImportances.flatMap((driverOption) =>
        driverOption.subCategories.flatMap(
          (subCategory) => subCategory.subCategoryName,
        ),
      ),
    ),
  ];

  if (subCategoryRows.length === 0) return;

  return (
    <Stack space="xlarge">
      {subCategoryRows.map((subCategoryRow) => (
        <Stack space="medium" key={`ROW_${subCategoryRow}_LEVELOFIMPORTANCE}`}>
          <Tiles columns={tilesColumns as TilesColumns} space="small">
            {levelOfImportances.map((driverOption) => (
              <Text
                size="small"
                weight="strong"
                component="h3"
                key={`TITLE_${driverOption.driverOptionName}_${subCategoryRow}_LEVELOFIMPORTANCE}`}
              >
                {subCategoryRow}
              </Text>
            ))}
          </Tiles>

          <Tiles columns={tilesColumns as TilesColumns} space="small">
            {levelOfImportances.map((driverOption) => {
              const subCategory = driverOption.subCategories.find(
                (item) => item.subCategoryName === subCategoryRow,
              );
              return (
                <Stack
                  space="medium"
                  key={`ROW_${driverOption.driverOptionName}_LEVEL_OF_IMPORTANCE`}
                >
                  {subCategory &&
                    subCategory.driverOptions.map((subCategoryDriverOption) => (
                      <BarChartGroup
                        label={subCategoryDriverOption.driverOptionName}
                        tooltip={
                          <Text>
                            This is how candidates sort their preferences for
                            the components of Work-life balance. Candidates have
                            the option to choose more than one must-have.
                          </Text>
                        }
                        items={subCategoryDriverOption.items}
                        key={`BARCHART_${subCategory.subCategoryName}_${subCategoryDriverOption.driverOptionName}`}
                      />
                    ))}
                </Stack>
              );
            })}
          </Tiles>
        </Stack>
      ))}
    </Stack>
  );
};

LevelOfImportance.displayName = 'LevelOfImportance';
